<template>
	<div
		class="chat-box ignore-chat-box"
		v-if="currentTalk"
		:class="currentTalk.talker"
		:style="{ ...currentTalk.style }"
	>
		<audio
			ref="audioTip"
			:src="currentTalk.audio"
			autoplay
			style="display: none"
			@ended="changeAudioState(false)"
		></audio>
		<div
			class="chat-content"
			v-if="currentTalk.talker"
		>
			<div class="talker">{{ currentTalk.talkerCHN }}：</div>
			<div class="content">
				{{ currentTalk.content }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		talkList: {
			type: Array,
			default: () => [],
		},
		talkIndex: {
			type: Number,
			default: -1,
		},
		mode: {
			type: String,
			default: "default",
		},
	},
	computed: {
		currentTalk() {
			let { talkIndex, talkList } = this;
			if (talkIndex === -1 || talkIndex >= talkList.length) {
				return false;
			}
			return talkList[talkIndex];
		},
		audioSrc() {
			return this.currentTalk?.audio;
		},
	},
	watch: {
		audioSrc: {
			immediate: true,
			handler(val) {
				let _this = this;
				if (val) {
					_this.$nextTick(() => {
						if (_this.mode === "fengniao") {
							const isFengNiao = val.indexOf("fengniao") > -1;
							if (isFengNiao) {
								_this.$refs.audioTip.pause();
								_this.$emit("playFengNiao", val);
							} else {
								_this.$refs.audioTip.play();
							}
						} else {
							_this.$refs.audioTip.play();
						}
						// this.changeAudioState(true);
						if (!_this.currentTalk.talker) {
							// 无对话角色 为旁白 不可跳过
							_this.changeAudioState(true);
							return;
						}
						_this.changeAudioState(false); // 开发环境不用听完可直接点击跳过对话
					});
				}
			},
		},
	},
	methods: {
		changeAudioState(state) {
			this.$emit("audioStateChange", state);
		},
	},
};
</script>

<style lang="scss" scoped>
.chat-box {
	position: absolute;
	width: 1000px;
	height: 420px;
	bottom: -60px;
	left: 60px;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: 0 0;
	z-index: 10;
	animation: glow-in 2s both;

	.chat-content {
		padding-left: 290px;
		padding-right: 50px;
		padding-top: 110px;
		box-sizing: border-box;
		color: #b35504;
		font-size: 28px;
		letter-spacing: 1px;
		font-weight: bold;

		.talker {
			margin-bottom: 0.1em;
		}
	}

	&.magician {
		background-image: url("./images/chat_box_magician.png");
	}

	&.knight {
		background-image: url("./images/chat_box_knight.png");
	}

	&.musician {
		background-image: url("./images/chat_box_musician.png");
	}

	&.horse {
		background-image: url("./images/chat_box_horse.png");
	}

	&.bird {
		background-image: url("./images/chat_box_bird.png");
	}

	&.shimengmo {
		background-image: url("./images/chat_box_shimengmo.png");
	}

	&.eagle {
		background-image: url("./images/chat_box_eagle.png");
	}

	&.zuoluo {
		background-image: url("./images/chat_box_zuoluo.png");
	}

	&.shimen {
		background-image: url("./images/chat_box_shimen.png");
	}

	&.geza {
		background-image: url("./images/chat_box_geza.png");
	}

	&.goose {
		background-image: url("./images/chat_box_goose.png");
	}

	&.blackman {
		background-image: url("./images/chat_box_blackman.png");
	}

	&.chouyou {
		background-image: url("./images/chat_box_chouyou.png");
	}

	&.panda {
		background-image: url("./images/chat_box_panda.png");
	}

	&.worm {
		background-image: url("./images/chat_box_worm.png");
	}

	&.butterfly {
		background-image: url("./images/chat_box_butterfly.png");
	}

	&.seadog {
		background-image: url("./images/chat_box_seadog.png");
	}

	&.dolphin {
		background-image: url("./images/chat_box_dolphin.png");
	}

	&.whale {
		background-image: url("./images/chat_box_whale.png");
	}

	&.mirror {
		background-image: url("./images/chat_box_mirror.png");
	}

	&.mermaid {
		background-image: url("./images/chat_box_mermaid.png");
	}
}
</style>
